import {
  Typography,
  Grid,
  Box,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import moment from "moment";
import { Constant } from "../../../../../../constants/constant";
import { useProgramDetailStore } from "../../../../../../store/adminPageStore/patientDetailsStore/programDetailsStore/programDetailsStore";
import { useEnrollmentMainStore } from "../../../../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";

type ProgramItems = {
  program: string;
  product: string;
  couponcode: string;
  primarycontact: string;
  secondarycontact: string;
  doctor_name: string;
  hospital: string;
  treatmentcycle: string;
  dosage: string;
  diagnosis: string;
  comments: string;
  no_of_therapy_months: number;
  no_of_free_months: number;
  last_infusion_date: Date | string;
  next_infusion_date: Date | string;
  prescriptiondate: Date | string;
  treatmentdate: Date | string;
  transplantdate: Date | string;
  freecycledate: Date | string;
  total_cycles_completed: number;
  total_cycles_remaining: number;
  pack_scheme: string;
};

type TacTestData = { type: string; value: number };

const EisaiStaticProgram: React.FC = () => {
  const { handleEdit } = useProgramDetailStore();
  const { selectedCard } = useEnrollmentMainStore();

  const { control, setValue } = useForm<ProgramItems>({
    mode: "onChange",
  });

  useEffect(() => {
    setValue(
      "doctor_name",
      selectedCard.doctor_full_name ? selectedCard?.doctor_full_name : " "
    );
    setValue("hospital", selectedCard.hospital ? selectedCard?.hospital : "");
    setValue(
      "prescriptiondate",
      selectedCard.prescription_date
        ? moment(selectedCard.prescription_date.toString()).format("ll")
        : " "
    );
    setValue(
      "transplantdate",
      selectedCard.transplant_date
        ? moment(selectedCard.transplant_date.toString()).format("ll")
        : " "
    );
    setValue(
      "treatmentdate",
      selectedCard.treatment_start_date
        ? moment(selectedCard.treatment_start_date.toString()).format("ll")
        : " "
    );
    setValue(
      "freecycledate",
      selectedCard.next_free_cycle_date
        ? moment(selectedCard.next_free_cycle_date.toString()).format("ll")
        : " "
    );
    setValue("treatmentcycle", selectedCard?.treatment_cycle);
    setValue("couponcode", selectedCard.coupon_code);
    setValue("diagnosis", selectedCard.diagnosis);
    setValue("dosage", selectedCard?.dosage);
    setValue("program", selectedCard.program.name);
    setValue("product", selectedCard.product.name);
    setValue("primarycontact", selectedCard.mobile);
    setValue("secondarycontact", selectedCard.alt_mobile);
    setValue("no_of_therapy_months", selectedCard.no_of_therapy_months);
    setValue("no_of_free_months", selectedCard.no_of_free_months);
    setValue("total_cycles_completed", selectedCard.total_cycles_completed);
    setValue("total_cycles_remaining", selectedCard.total_cycles_remaining);
    setValue("pack_scheme", selectedCard.pack_scheme);
    setValue(
      "last_infusion_date",
      selectedCard.last_infusion_date
        ? moment(selectedCard.last_infusion_date.toString()).format("ll")
        : " "
    );
    setValue(
      "next_infusion_date",
      selectedCard.next_infusion_date
        ? moment(selectedCard.next_infusion_date.toString()).format("ll")
        : " "
    );

    setValue("comments", selectedCard?.comments);
  }, [selectedCard.id]);

  return (
    <Box overflow="scroll" height="65vh" paddingBottom={10}>
      <Stack
        justifyContent="space-between"
        direction={"row"}
        position={"fixed"}
        width={"42%"}
        bgcolor={"white"}
        zIndex={10}
        paddingBottom={1}
      >
        <Typography
          sx={{
            fontFamily: "Segoe UI",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "18px",
            color: "#212121",
          }}
        >
          Program Details
        </Typography>
        <Box>
          <Button
            variant="text"
            onClick={() => handleEdit(1)}
            sx={{
              color: "#3F51B7",
              fontSize: "14px",
              fontFamily: "Segoe UI",
              fontStyle: " normal",
              fontWeight: 500,
              alignSelf: "flex-end",
            }}
          >
            SEND TO CFA
          </Button>
          <Button
            variant="text"
            onClick={() => handleEdit(2)}
            sx={{
              color: "#3F51B7",
              fontSize: "14px",
              fontFamily: "Segoe UI",
              fontStyle: " normal",
              fontWeight: 500,
              alignSelf: "flex-end",
            }}
          >
            EDIT
          </Button>
        </Box>
      </Stack>

      <Grid container spacing={4} mt={1}>
        <Grid item xs={6}>
          <Typography
            sx={{
              fontFamily: "Segoe UI",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              marginBottom: "6px",
              color: "#6B6B6B",
            }}
          >
            Program
          </Typography>
          <Controller
            name="program"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                size="small"
                inputProps={{ readOnly: true }}
                variant="standard"
                value={value}
                error={error && true}
                helperText={error?.message}
                onChange={onChange}
                sx={{
                  borderRadius: "3.5px",
                }}
              />
            )}
            rules={{
              required: { value: true, message: Constant.FIELD_REQUIRED },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            sx={{
              fontFamily: "Segoe UI",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              marginBottom: "6px",
              color: "#6B6B6B",
            }}
          >
            Product
          </Typography>
          <Controller
            name="product"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                size="small"
                inputProps={{ readOnly: true }}
                variant="standard"
                value={value}
                error={error && true}
                helperText={error?.message}
                onChange={onChange}
                sx={{
                  borderRadius: "3.5px",
                }}
              />
            )}
            rules={{
              required: { value: true, message: Constant.FIELD_REQUIRED },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography color={"text.secondary"} variant="caption">
            Is an Indian National?
          </Typography>
          <Typography color={"black"} variant="subtitle2">
            {selectedCard.is_indian_national == 1 ? "Yes" : "No"}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography
            sx={{
              fontFamily: "Segoe UI",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              marginBottom: "6px",
              color: "#6B6B6B",
            }}
          >
            Primary Contact
          </Typography>
          <Controller
            name="primarycontact"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="standard"
                value={value}
                size="small"
                error={error && true}
                inputProps={{ readOnly: true }}
                helperText={error?.message}
                onChange={onChange}
                sx={{
                  borderRadius: "3.5px",
                }}
              />
            )}
            rules={{
              required: { value: true, message: Constant.FIELD_REQUIRED },
              pattern: {
                value: Constant.CORRECT_MOBILE.VALUE,
                message: Constant.CORRECT_MOBILE.MESSAGE,
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            sx={{
              fontFamily: "Segoe UI",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              marginBottom: "6px",
              color: "#6B6B6B",
            }}
          >
            Secondary Contact
          </Typography>
          <Controller
            name="secondarycontact"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="standard"
                value={value}
                inputProps={{ readOnly: true }}
                size="small"
                error={error && true}
                helperText={error?.message}
                onChange={onChange}
                sx={{
                  borderRadius: "3.5px",
                }}
              />
            )}
            rules={{
              required: { value: true, message: Constant.FIELD_REQUIRED },
              pattern: {
                value: Constant.CORRECT_MOBILE.VALUE,
                message: Constant.CORRECT_MOBILE.MESSAGE,
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            sx={{
              fontFamily: "Segoe UI",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              marginBottom: "6px",
              color: "#6B6B6B",
            }}
          >
            Doctor
          </Typography>
          <Controller
            name="doctor_name"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="standard"
                inputProps={{ readOnly: true }}
                value={value}
                size="small"
                error={error && true}
                helperText={error?.message}
                onChange={onChange}
                sx={{
                  borderRadius: "3.5px",
                }}
              />
            )}
            rules={{
              required: { value: true, message: Constant.FIELD_REQUIRED },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            sx={{
              fontFamily: "Segoe UI",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              marginBottom: "6px",
              color: "#6B6B6B",
            }}
          >
            Hospital/Clinic
          </Typography>
          <Controller
            name="hospital"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="standard"
                value={value}
                size="small"
                error={error && true}
                inputProps={{ readOnly: true }}
                helperText={error?.message}
                onChange={onChange}
                sx={{
                  borderRadius: "3.5px",
                }}
              />
            )}
            rules={{
              required: { value: true, message: Constant.FIELD_REQUIRED },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            sx={{
              fontFamily: "Segoe UI",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              marginBottom: "6px",
              color: "#6B6B6B",
            }}
          >
            Treatment Cycle
          </Typography>
          <Controller
            name="treatmentcycle"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="standard"
                value={value}
                size="small"
                error={error && true}
                inputProps={{ readOnly: true }}
                helperText={error?.message}
                onChange={onChange}
                sx={{
                  borderRadius: "3.5px",
                }}
              />
            )}
            rules={{
              required: { value: true, message: Constant.FIELD_REQUIRED },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            sx={{
              fontFamily: "Segoe UI",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              marginBottom: "6px",
              color: "#6B6B6B",
            }}
          >
            Pack Scheme
          </Typography>
          <Controller
            name="pack_scheme"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="standard"
                value={value}
                size="small"
                error={error && true}
                inputProps={{ readOnly: true }}
                helperText={error?.message}
                onChange={onChange}
                sx={{
                  borderRadius: "3.5px",
                }}
              />
            )}
            rules={{
              required: { value: true, message: Constant.FIELD_REQUIRED },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            sx={{
              fontFamily: "Segoe UI",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              marginBottom: "6px",
              color: "#6B6B6B",
            }}
          >
            Diagnosis
          </Typography>
          <Controller
            name="diagnosis"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="standard"
                value={value}
                size="small"
                error={error && true}
                inputProps={{ readOnly: true }}
                helperText={error?.message}
                onChange={onChange}
                sx={{
                  borderRadius: "3.5px",
                }}
              />
            )}
            rules={{
              required: { value: true, message: Constant.FIELD_REQUIRED },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            sx={{
              fontFamily: "Segoe UI",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              marginBottom: "6px",
              color: "#6B6B6B",
            }}
          >
            Dosage
          </Typography>
          <Controller
            name="dosage"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="standard"
                value={value}
                size="small"
                error={error && true}
                inputProps={{ readOnly: true }}
                helperText={error?.message}
                onChange={onChange}
                sx={{
                  borderRadius: "3.5px",
                }}
              />
            )}
            rules={{
              required: { value: true, message: Constant.FIELD_REQUIRED },
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <Typography
            sx={{
              fontFamily: "Segoe UI",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              marginBottom: "6px",
              color: "#6B6B6B",
            }}
          >
            Prescription Date
          </Typography>
          <Controller
            name="prescriptiondate"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="standard"
                value={value}
                size="small"
                error={error && true}
                inputProps={{ readOnly: true }}
                helperText={error?.message}
                onChange={onChange}
                sx={{
                  borderRadius: "3.5px",
                }}
              />
            )}
            rules={{
              required: { value: true, message: Constant.FIELD_REQUIRED },
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <Typography
            sx={{
              fontFamily: "Segoe UI",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              marginBottom: "6px",
              color: "#6B6B6B",
            }}
          >
            Transplant Date
          </Typography>
          <Controller
            name="transplantdate"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                size="small"
                variant="standard"
                value={value}
                error={error && true}
                inputProps={{ readOnly: true }}
                helperText={error?.message}
                onChange={onChange}
                sx={{
                  borderRadius: "3.5px",
                }}
              />
            )}
            rules={{
              required: { value: true, message: Constant.FIELD_REQUIRED },
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <Typography
            sx={{
              fontFamily: "Segoe UI",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              marginBottom: "6px",
              color: "#6B6B6B",
            }}
          >
            Treatment Start Date
          </Typography>
          <Controller
            name="treatmentdate"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                size="small"
                variant="standard"
                value={value}
                error={error && true}
                inputProps={{ readOnly: true }}
                helperText={error?.message}
                onChange={onChange}
                sx={{
                  borderRadius: "3.5px",
                }}
              />
            )}
            rules={{
              required: { value: true, message: Constant.FIELD_REQUIRED },
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            sx={{
              fontFamily: "Segoe UI",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              marginBottom: "8px",
              color: "#6B6B6B",
            }}
          >
            Comments
          </Typography>
          <Controller
            name="comments"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="standard"
                size="small"
                aria-multiline
                value={value}
                inputProps={{ readOnly: true }}
                onChange={onChange}
                sx={{
                  borderRadius: "3.5px",
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default EisaiStaticProgram;
