import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import Add from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import AddNewEnrollment from "./AddNewEnrollment";
import { pageRoutes } from "../../../routes";
import LogoutIcon from "@mui/icons-material/Logout";
import { useEnrollmentMainStore } from "../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import { useLoginPageStore } from "../../../store/loginPageStore/loginPageStore";
import { LocalLoggedInUser } from "../../../data/usecases/local-logged-in-user";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { useAppointmentDetailsStore } from "../../../store/adminPageStore/patientDetailsStore/appointmentDetailsStore/appointmentDetailsStore";
import { useConsentStore } from "../../../store/patientConsentStore/consentStore";
import { getDomain } from "../../../domain/Utils";
import DomainName from "../../../domain/domain";

const HeaderActions: React.FC = () => {
  const navigate = useNavigate();
  const { handleAddNewEnrollment } = useEnrollmentMainStore();
  const { loginDetails, handleLogOut } = useLoginPageStore();
  const { showAllAppointments, handleShowAllAppointments } =
    useAppointmentDetailsStore();
  const { consentTokenEnrolmentDetails } = useConsentStore();
  const storage = LocalJsonStorage.getInstance();
  const loggedInUser = new LocalLoggedInUser(storage);
  const handleLogout = () => {
    handleLogOut();
    loggedInUser.logout();
    navigate(pageRoutes.loginPage);
  };

  return (
    <Stack direction="row" spacing={5}>
      {consentTokenEnrolmentDetails.consent_acknowledgement &&
        getDomain() != DomainName.EISAI && (
          <Button
            color="secondary"
            onClick={() => handleShowAllAppointments()}
            className="addnewbutton"
            sx={{ width: "100px" }}
          >
            <Typography textTransform="none">
              {showAllAppointments ? "Home" : "Calender"}
            </Typography>
          </Button>
        )}
      {loginDetails.role_id !== 10 && (
        <Button
          color="secondary"
          variant="contained"
          onClick={() => handleAddNewEnrollment()}
          className="addnewbutton"
          startIcon={<Add />}
        >
          Add New
        </Button>
      )}

      <Button
        onClick={handleLogout}
        color={"error"}
        startIcon={<LogoutIcon fontSize="small" />}
      >
        <Typography variant="subtitle2" fontWeight={600}>
          LOG OUT
        </Typography>
      </Button>
      <AddNewEnrollment />
    </Stack>
  );
};
export default HeaderActions;
