import {
  Box,
  Button,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useOrderDetailsStore } from "../../../store/stockistPageStore/orderStore/orderDetailsStore";
import { Status } from "../../../constants/enums/status";
import CheckIcon from "@mui/icons-material/Check";
import { LoadingButton } from "@mui/lab";
import TextArea from "antd/es/input/TextArea";
import GaTextArea from "../GaTextArea";
import { useState } from "react";
import FileUpload from "../FileUpload";

type Props = {
  open: boolean;
  handleClose: Function;
  handleSuccessCallback: (
    file: File | null,
    handleModalClose: Function
  ) => void;
  loading: boolean;
  documentType: string;
  handleSelectDocumentType: Function;
};

const style = {
  borderRadius: "12px",
  display: "flex",
  gap: 2,
  flexDirection: "column",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const AddOrderDocumentsModal: React.FC<Props> = ({
  open,
  handleClose,
  handleSuccessCallback,
  loading,
  documentType,
  handleSelectDocumentType,
}) => {
  const documentTypeValues = [
    {
      title: "Supporting document",
      code: "ORDER_SUPPORTING_DOCUMENT",
    },
    {
      title: "Proof of delivery",
      code: "POD",
    },
  ];

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
      }}
    >
      <Box sx={style}>
        <TextField
          select
          fullWidth
          value={documentType}
          onChange={(e) => handleSelectDocumentType(e.target.value)}
          label="Select Document Type"
        >
          {documentTypeValues.length > 0 &&
            documentTypeValues.map((option) => (
              <MenuItem key={option.code} value={option.code}>
                {option.title}
              </MenuItem>
            ))}
        </TextField>
        {documentType && (
          <FileUpload
            buttonTitle="Upload Documents"
            onSuccessCallBack={handleSuccessCallback}
            loading={loading}
          />
        )}
      </Box>
    </Modal>
  );
};
export default AddOrderDocumentsModal;
