import React, { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, Typography } from "@mui/material";
import SendtoCFA from "./SendToCFA";
import LabOrder from "./LabOrder";
import { getDomain } from "../../../../../domain/Utils";
import DomainName from "../../../../../domain/domain";
import { useEnrollmentMainStore } from "../../../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import { Program } from "../../../../../constants/enums/program";

type Props = {};

const OrderPlacingTabs = (props: Props) => {
  const [value, setValue] = useState("1");
  const { selectedCard } = useEnrollmentMainStore();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <Box>
        <TabList onChange={handleChange}>
          <Tab
            label={
              <Typography variant="subtitle2" fontWeight={550}>
                {selectedCard.program_id.toString() == Program.EISAI
                  ? "FOC Orders"
                  : "Commerical Orders"}
              </Typography>
            }
            value="1"
          />
          {selectedCard.program_id.toString() != Program.EISAI && (
            <Tab
              label={
                <Typography variant="subtitle2" fontWeight={550}>
                  Lab Orders
                </Typography>
              }
              value="2"
            />
          )}
        </TabList>
        <TabPanel value="1">
          <SendtoCFA />
        </TabPanel>
        {selectedCard.program_id.toString() != Program.EISAI && (
          <TabPanel value="2">
            <LabOrder />
          </TabPanel>
        )}
      </Box>
    </TabContext>
  );
};

export default OrderPlacingTabs;
